<template>
    <div>
        
        <CoolLightBox 
        slideshowColorBar="#B4DD45"
        v-if="good"
        :items="getImageLighbox" 
        :index="lightIndex"
        @close="lightIndex = null">
        </CoolLightBox>

        <section id="goodItem" class="catalog-wrapper" v-if="good">
            <div class="container">
                <div class="row">
                    <!-- <pre>{{good}}</pre> -->
                    <div class="col-lg-6" v-if="good.gallery.length"> 
                        <div class="img-gallery text-center">
                            <div class="new-good" v-if="good.labels.includes('new')">Новинка</div>
                            <swiper ref="gallerySwiper" :options="swiperOptions">
                                <swiper-slide>
                                    <div class="single-slider-img" @click="lightIndex = 0" :style="{'background-image': 'url(' + good.picture + ')'}"></div> 
                                </swiper-slide>
                                <swiper-slide v-for="(item, index) in good.gallery" :key="index">
                                    <div class="single-slider-img" @click="lightIndex = index + 1" :style="{'background-image': 'url(' + item+ ')'}" ></div> 
                                </swiper-slide>
                            </swiper>
                            <div @click.stop class="prevBtn prevBtn2"></div>
                            <div @click.stop class="nextBtn nextBtn2"></div>
                        </div> 
                         <div class="mini-zoom">
                            <div @click="lightIndex = 0" class="mini-img" :style="{'background-image': 'url(' + good.picture + ')'}"></div>
                            <div class="mini-img" v-for="(item, index) in good.gallery" :key="index" :style="{'background-image': 'url(' + item+ ')'}" @click="lightIndex = index + 1"></div>
                        </div>
        
                    </div>
                    <div class="col-lg-6" v-else>
                        <div class="text-center">
                            <div class="new-good" v-if="good.labels.includes('new')">Новинка</div>
                            <div class="single-slider-img" 
                            :style="{'background-image': 'url(' + good.picture+ ')'}"
                            @click="lightIndex = 0"></div> 
                        </div>
                    </div>
                    <div class="col-lg-6">

                        <h3 v-html="good.name"></h3>
                        <div class="row">
                            <div class="col-lg-3">
                                <h4>Контейнер</h4>
                                <p class="black-txt">{{good.container[getReg]}}</p>
                            </div>
                           
                            <div class="col-lg-3" 
                        v-if="good.quantity[getReg] > 0 && good.price[getReg] ">
                               <h4>Кол-во, шт.</h4>
                                <div class="count-box" >
                                   <div class="count-btn" @click="changeAmount(-1)"><img src="../assets/img/min.svg"></div>
                                   <span>{{amountToAdd}}</span>
                                   <div class="count-btn" @click="changeAmount(1)"><img src="../assets/img/plus.svg"></div>
                               </div>
                            </div>
                            <div class="col-lg-4">
                                <!-- <div class="inStock" v-if="good.quantity[getReg] > 0 && good.price[getReg]"><div class="bullet"></div>В наличии</div> -->
                                <div class="inStock" v-if="good.inStock === 'instock'"><div class="bullet"></div>В наличии</div>
                                
                                <div class="inStock" v-else><div class="bullet" style="background-color:#E56036 ;">
                                    </div>Товар закончился</div>

                            </div>
                        </div>

                        <!-- <div class="notify" v-if="good.labels.includes('preorder') && region === 'Санкт-Петербург и ЛО (самовывоз на весну)'">
                            <span class="mdi mdi-alert-circle"></span>
                            Обращаем ваше внимание, что данный товар доступен для самовывоза в АВГУСТЕ
                        </div> -->

                        <!-- <div class="price-box-single" 
                        v-if="good.quantity[getReg] > 0 && good.price[getReg] && good.labels.includes('preorder') && region === 'Санкт-Петербург и ЛО (самовывоз на весну)'">
                            <div class="bigPrice" >{{good.price[getReg]}} ₽</div>
                            <button class="to-cart" @click="addFromSingle(good)"><span class="mdi mdi-cart"></span> В корзину</button>
                        </div>

                        <div class="price-box-single" v-else-if="good.quantity[getReg] > 0 && good.price[getReg]">
                            <div class="bigPrice" >{{good.price[getReg]}} ₽</div>
                            <button class="to-cart" @click="addFromSingle(good)"><span class="mdi mdi-cart"></span> В корзину</button>
                        </div> -->


                        <div class="price-box-single" v-if="good.inStock === 'instock'">
                            <div class="bigPrice" >{{good.price[getReg]}} ₽</div>
                            <button class="to-cart" @click="addFromSingle(good)"><span class="mdi mdi-cart"></span> В корзину</button>
                        </div>
                        
                        <div class="price-box-single" v-else>
                        </div>

                    </div>
                    <div class="col-lg-12 mt-30">
                        <div class="tab-btns">
                            <h5 :class="{'act-tab' : tabOpened == 0}" @click="tabOpened = 0">Описание</h5>
                            <h5 :class="{'act-tab' : tabOpened == 1}" @click="tabOpened = 1">Детали</h5>
                            <h5 :class="{'act-tab' : tabOpened == 2}" @click="tabOpened = 2">Комментарии: {{comments.length}}</h5>
                        </div>
                        
                        <div v-if="tabOpened == 0">
                            <p class="grey-txt" v-if="good.description === ''">Описание отсутствует</p>
                            <p class="grey-txt" v-else v-html="good.description"></p>
                        </div>
                        
                        <SpecTable v-else-if="tabOpened == 1" :spec="good.spec" :catSlug="good.category.slug" />
                        
                        <div v-else>
                            <Comments :comments="comments" />

                            <div v-if="user">
                                <textarea class="comment-inp" placeholder="Ваш комментарий" cols="30" rows="5" 
                                :class="{errorInp : $v.comment.$dirty && !$v.comment.required}" v-model="comment"></textarea>
                                <button @click="addComment" class="add-comm" v-if="!load">Отправить</button>
                                <loader style="text-align:left;" v-else />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Popular :slpw="3" v-if="good" class="catalog-wrapper" />



        <div class="loader-box" v-if="!good">
            <div>
                <img src="@/assets/img/dlogo.svg" class="loader">
                <br>
                <h3>Загружаем товар</h3>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import loader from '../components/ui/loader.vue'
import Popular from '../components/Popular.vue'
import SpecTable from '../components/SpecTable.vue'
import { mapGetters } from 'vuex'
// import ImgModal from '../components/ui/ImgModal.vue'
import { API } from '../config';
import Comments from '../components/ui/Comments.vue'
import {required } from "vuelidate/lib/validators";

export default {
    props: ["id"],
    components: {Popular, SpecTable, Comments, loader},
    computed: {
        ...mapGetters({
            good: "catalog/getSingleGood",
            region: "catalog/getRegion",
            user: "auth/getAuthenticated"
        }),
        getReg(){
            if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                return 'sl'
            }else{
                return 'rg'
            }
        },
        getImageLighbox(){
            let imgArray = []
            if(this.good.gallery.length){
                imgArray = [this.good.picture, ...this.good.gallery]
            }else{
                imgArray.push(this.good.picture)
            }

            return imgArray
        }
    },
    watch:{
        id(){
            this.$store.dispatch('catalog/loadSingleGood', this.id)
        },
        region(){
            this.amountToAdd = 1
        }   
    },
    validations: {
		comment: {
			required
		}
    },
    methods: {
        addComment(){
            this.load = true
            if(this.$v.$invalid) {
					this.$v.$touch();
					return;
			}
            axios
            .post(`${API}post/comment?post_id=${this.good.id}&user_id=${this.user.id}&text=${this.comment}`)
            .then(res =>{
                this.comments = res.data
                this.load = false
                this.comment = ''
            })
            .catch(err =>{
                this.load = false
                this.comment = ''
            })
        },
        addFromSingle(goodItem){
            let param = {
                good: goodItem,
                amount: this.amountToAdd
            }

            this.$store.dispatch("cart/addFromSingle", param)
            .then(prom =>{
                if(prom === 'success'){
                    this.$toasted.show(
                        "Товар добавлен в корзину", { 
                        theme: "toasted-primary", 
                        position: "top-right", 
                        duration : 2000
                    });
                }
                if(prom === 'error'){
                    this.$toasted.show(
                        "Больше допустимого", { 
                        theme: "toasted-primary", 
                        position: "top-right", 
                        duration : 2000,
                        className: ['err-toast',]
                    });
                }
            })

        },
        changeAmount(param){
            if(this.amountToAdd == 1 && param == -1){
                return
            }else{
                if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                    if(this.amountToAdd == this.good.quantity.sl && param == 1){
                        return
                    }else{
                        this.amountToAdd += param
                    }
                }else{
                    if(this.amountToAdd == this.good.quantity.rg && param == 1){
                        return
                    }else{
                        this.amountToAdd += param
                    }
                }
            }
        }
    },
    data(){
        return{
            lightIndex: null,
            load: false,
            comment: '',
            comments: [],
            tabOpened: 0,
            amountToAdd: 1,
            swiperOptions: {
                spaceBetween: 0,
                draggable: true,
                touchRatio: 1,
                centeredSlides: true,
                loop: true,    
                infinite: true,
                threshold: 50,
                speed: 400,
                watchSlidesVisibility: true,
                    navigation: {
                        prevEl: '.prevBtn2',
                        nextEl: '.nextBtn2',
                    },
            }
        }
    },
    created(){
        this.$store.dispatch('catalog/loadSingleGood', this.id).then(res =>{
            axios
            .get(`${API}get/comments?id=${this.good.id}`)
            .then(res =>{
                this.comments = res.data
            })
        })

        
    },
    mounted(){
        let options = {offset: 0,force: true}
        this.$scrollTo('header', 0, options)
    }
}
</script>

<style scoped>
.errorInp{
    border: 1px red solid;
}
#goodItem{
    min-height: inherit;
}
#popular{
    margin-top: 0
}
.prevBtn2{
    transform: translateY(-21px);
}
.nextBtn2{
    transform: translateY(-21px) rotate(180deg);
}
</style>