import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Catalog from '../views/Catalog.vue'
import Single from '../views/Single.vue'
import AllGoods from '../views/AllGoods.vue'
import Category from '../views/Category.vue'
import Subcategory from '../views/Subcategory.vue'
import Cart from '../views/Cart.vue'
import FilterPage from '../views/FilterPage.vue'

import Success from '../views/Success.vue'
import SearchPage from '../views/SearchPage.vue'

import Contacts from '../views/Contacts.vue'
import About from '../views/About.vue'
import Where from '../views/Where.vue'
import Pitomnik from '../views/Pitomnik.vue'
import NewProducts from '../views/NewProducts.vue'
import Certificate from '../views/Certificate.vue'
import DetailsProducts from '../views/DetailsProducts.vue'
import Merch from '../views/Merch.vue'
import Delivery from '../views/Delivery.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Recovery from '../views/Recovery.vue'
import Profile from '../views/Profile.vue'
import Notfound from '../views/Notfound.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product-category',
    component: Catalog,
    children: [
      {
        path: '/',
        component: AllGoods
      },
      {
        path: '/product-category/:cat',
        component: Category,
        props: true
      },
      {
        path: '/product-category/:cat/:sub',
        component: Subcategory,
        props: true
      },
      {
        path: '/new-products',
        component: NewProducts,
      },
      {
        path: '/certificate',
        component: Certificate,
        name: 'certificate'
      },
      {
        path: '/details-products',
        component: DetailsProducts
      },
      {
        path: '/product/:id',
        component: Single,
        props: true
      },
      {
        path: '/filter',
        component: FilterPage,
        name: 'Filter'
      }
    ]
  },
  {
    path: '/pechatnaya-produkcziya',
    component: Merch,
    name: 'merch'
  },
  {
    path:'/success-order',
    component: Success
  },
  {
    path: '/cart',
    component: Cart,
  },
  {
    path: '/delivery',
    component: Delivery
  },
  {
    path: '/enter',
    component: Login
  },
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/my-account/lost-password',
    component: Recovery
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/getAuthenticated"]) {
        next()
      } else {
        if (to.path != "/enter") {
          next("/enter")
        }
        else {
          next()
        }
      }
    }
  },
  {
    path: '/o-nas',
    component: About,
  },
  {
    path: '/v-pitomnike',
    component: Pitomnik
  },
  {
    path: '/gde-kupit',
    component: Where
  },
  {
    path: '/contacts',
    component: Contacts
  },
  {
    path: '/search-results=:id',
    component: SearchPage,
    props: true
  },
  {
    path: '*',
    component: Notfound
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
  }
})

export default router