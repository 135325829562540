<template>
  <div class="good-item">
    <div
      class="like-btn"
      @click="likeItem"
    >
      <span
        class="mdi mdi-cards-heart"
        v-if="wishlist.includes(goodItem.id)"
      />
      <span
        class="mdi mdi-heart-outline"
        v-else
      />
    </div>
    <router-link
      tag="a"
      :to="`/product/${goodItem.slug}`"
    >
      <div class="img-box">
        <div
          class="new-good"
          v-if="goodItem.labels.includes('new')"
        >
          Новинка
        </div>
        <div
          class="first-img"
          v-lazy:background-image="goodItem.picture"
        />
      </div>
    </router-link>
    <div class="under-img">
      <router-link
        tag="a"
        :to="`/product/${goodItem.slug}`"
      >
        <h4 v-html="goodItem.name" />
      </router-link>
            

      <!-- <div class="price-box" v-if="goodItem.quantity[getReg] > 0 && goodItem.price[getReg] && goodItem.labels.includes('preorder') && region === 'Санкт-Петербург и ЛО (самовывоз на весну)'">
                <div class="price">{{goodItem.price[getReg]}} ₽</div>
                <button class="to-cart" @click="addToCart(goodItem)">В корзину</button>
            </div>
            <div class="price-box" v-else-if="goodItem.quantity[getReg] > 0 && goodItem.price[getReg]">
                <div class="price">{{goodItem.price[getReg]}} ₽</div>
                <button class="to-cart" @click="addToCart(goodItem)">В корзину</button>
            </div> -->
      <div
        class="price-box"
        v-if="goodItem.inStock === 'instock'"
      >
        <div class="price">
          {{ goodItem.price[getReg] }} ₽
        </div>
        <button
          class="to-cart"
          @click="addToCart(goodItem)"
        >
          В корзину
        </button>
      </div>
      <div
        class="price-box"
        v-else
      >
        <div class="inStock">
          <div
            class="bullet"
            style="background-color:#E56036 ;"
          />
          Товар закончился
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        goodItem: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            region: "catalog/getRegion",
            user: "auth/getAuthenticated",
            wishlist: "auth/getLikes"
        }),
        getReg(){
            if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                return 'sl'
            }else{
                return 'rg'
            }
        },
    },
    methods: {
        likeItem(){
            if(!this.user){
                this.$router.replace("/enter");
                return
            }
            let params ={
                user: this.user.id,
                good: this.goodItem.id
            }
            this.$store.dispatch('auth/setLike', params)
        },
        addToCart(goodItem){
            let param = {
                good: goodItem,
                amount: 1
            }

            this.$store.dispatch("cart/addFromSingle", param)
            .then(prom =>{
                if(prom === 'success'){
                    this.$toasted.show(
                        "Товар добавлен в корзину", { 
                        theme: "toasted-primary", 
                        position: "top-right", 
                        duration : 2000
                    });
                }
                if(prom === 'error'){
                    this.$toasted.show(
                        "Больше допустимого", { 
                        theme: "toasted-primary", 
                        position: "top-right", 
                        duration : 2000,
                        className: ['err-toast',]
                    });
                }
            })
        }
    }
}
</script>



<style scoped>
.good-item{
    margin-bottom: 40px;
}
.good-item h4{
    height: 57px;
    overflow: hidden;
    cursor: pointer;
}
</style>