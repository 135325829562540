<template>
    <section id="popular" >
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="flex-block">
                        <h2>ПОпулярные растения</h2>
                        <router-link tag="button" to="/product-category" class="green-btn hidden-xs hidden-sm" v-if="this.$route.name === 'Home' ">Перейти в КАТАЛОГ</router-link>
                    </div>
                </div>
            </div>
            <div class="row" v-if="popular.length">
                <div class="col-lg-12">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in popular" :key="index">
                            <div class="good-item">
                                <div class="like-btn" @click="likeItem(item.id)">
                                    <span class="mdi mdi-cards-heart" v-if="wishlist.includes(item.id)"></span>
                                    <span class="mdi mdi-heart-outline" v-else></span>
                                </div>
                                <router-link tag="a" :to="`/product/${item.slug}`">
                                    <div class="img-box"> 
                                        <div class="first-img" :style="{'background-image': 'url(' + item.picture+ ')'}">
                                        </div>
                                    </div>
                                </router-link>
                                
                                <div class="under-img">
                                    <router-link tag="a" :to="`/product/${item.slug}`">
                                        <h4>{{item.name}}</h4>
                                    </router-link>

                                    
                                    <!-- <div class="price-box" v-if="item.quantity[getReg] > 0 && item.price[getReg] && item.labels.includes('preorder') && region === 'Санкт-Петербург и ЛО (самовывоз на весну)'">
                                        <div class="price">{{item.price[getReg]}} ₽</div>
                                        <button class="to-cart" @click="addToCart(item)">В корзину</button>
                                    </div>
                                    <div class="price-box" v-else-if="item.quantity[getReg] > 0 && item.price[getReg]">
                                         <div class="price">{{item.price[getReg]}} ₽</div>
                                         <button class="to-cart" @click="addToCart(item)">В корзину</button>
                                    </div> -->
                                    <div class="price-box" v-if="item.inStock === 'instock'">
                                        <div class="price">{{item.price[getReg]}} ₽</div>
                                        <button class="to-cart" @click="addToCart(item)">В корзину</button>
                                    </div>
                                    <div class="price-box" v-else>
                                            <div class="inStock"><div class="bullet" style="background-color:#E56036 ;"></div>
                                            Товар закончился
                                        </div>
                                    </div>
                                     <!-- <div class="price-box" v-if="item.inStock === 'instock' &&  item.price[getReg] != 0">
                                        <div class="price">{{item.price[getReg]}} ₽</div>
                                        <button class="to-cart" @click="addToCart(item)">В корзину</button>
                                    </div>
                                    <div class="price-box" v-else>
                                            <div class="inStock"><div class="bullet" style="background-color:#E56036 ;"></div>
                                            Товар закончился
                                        </div>
                                    </div> -->
                                
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
            <div class="prevBtn prevPop"></div>
            <div class="nextBtn nextPop"></div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props:{
        slpw: {
            required: false,
            type: Number
        }
    },
    computed: {
        ...mapGetters({
            popular: "catalog/getPopular",
            region: "catalog/getRegion",
            user: "auth/getAuthenticated",
            wishlist: "auth/getLikes"
        }),
        getReg(){
            if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                return 'sl'
            }else{
                return 'rg'
            }
        },
    },
    methods: {
        likeItem(id){
            if(!this.user){
                this.$router.replace("/enter");
                return
            }
            let params ={
                user: this.user.id,
                good: id
            }
            this.$store.dispatch('auth/setLike', params)
        },
        addToCart(goodItem){
            let param = {
                good: goodItem,
                amount: 1
            }

            this.$store.dispatch("cart/addFromSingle", param)
            .then(prom =>{
                if(prom === 'success'){
                    this.$toasted.show(
                        "Товар добавлен в корзину", { 
                        theme: "toasted-primary", 
                        position: "top-right", 
                        duration : 2000
                    });
                }
                if(prom === 'error'){
                    this.$toasted.show(
                        "Больше допустимого", { 
                        theme: "toasted-primary", 
                        position: "top-right", 
                        duration : 2000,
                        className: ['err-toast',]
                    });
                }
            })
        }
    },
    data(){
        return{
            swiperOptions: {
                    spaceBetween: 30,
                    draggable: true,
                    touchRatio: 1,
                    centeredSlides: false,
                    loop: false,    
                    infinite: true,
                    threshold: 50,
                    speed: 400,
                    watchSlidesVisibility: true,
                    navigation: {
                        prevEl: '.prevPop',
                        nextEl: '.nextPop',
                    },
                    breakpoints: {
			            320: {
			              slidesPerView: 1.3,
			              spaceBetween: 10,
                          freeMode: true,
                          threshold: 10,
			              touchRatio: 1,
                            centeredSlides: false,
			            },
			            768: {
				          slidesPerView: 3,
				           touchRatio: 1,
                            centeredSlides: false,
				        },
				        1150: {
				          slidesPerView: this.slpw,
				          spaceBetween: 30,
				           touchRatio: 1,
                            centeredSlides: false,
				        }
			        }
                },
        }
    }
}
</script>