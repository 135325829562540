<template>
    <nav :class="{'white-nav' : this.$route.name !== 'Home'}">
        <div class="container">
            <div class="shapka">
                <ul class="main-nav">
                    <router-link tag="li" to="/product-category" class="wh-link catalog-btn-big">
                        <span class="mdi mdi-format-list-bulleted"></span>
                        Каталог <span class="hidden-xs">и заказ</span> растений
                    </router-link>
                    <li class="wh-link catalog-btn-big hidden-xs hidden-sm" @click="downloadPrice" style="margin-right:0;">
                        <span class="mdi mdi-download-multiple"></span>
                        Скачать прайс
                    </li>
                    <li class="wh-link catalog-btn-big hidden-xs hidden-sm" @click="goLibrary" style="background-color:#c8ea6d;">
                        <span class="mdi mdi-bookmark"></span>
                        Наш справочник
                    </li>
                    <router-link tag="li" to="/" class="wh-link hidden-xs hidden-sm">Главная</router-link>
                    <router-link tag="li" to="/o-nas" class="wh-link hidden-xs hidden-sm">О Нас</router-link>
                    
                     <!-- <router-link tag="li" to="/v-pitomnike" class="wh-link hidden-xs hidden-sm">В Питомнике</router-link> -->
                     <!-- <li><a href="https://library.sflora.ru/" target="_blank">Наш справочник</a></li> -->
                    
                    <router-link tag="li" to="/contacts" class="wh-link hidden-xs hidden-sm">Контакты</router-link>
                    <router-link tag="li" to="/gde-kupit" class="wh-link hidden-xs hidden-sm">Где купить</router-link>
                </ul>
                <ul class="main-nav  hidden-md hidden-lg">
                    <li style="padding:0;">
                        <p class="wh-link hidden-sm hidden-lg hidden-md" style="text-transform:none;font-size:8px;margin-bottom:0;">Ленинградская область,
                        Гатчинский район, <br> д. Парицы,
                        ул.Большая, д.69а</p>
                        <a class="wh-link" href="tel:+79213321380">+7 (921) 332-13-80</a>
                    </li>
                </ul>
                <div class="nav-socials hidden-xs">
                    <a href="https://wa.me/79213321380" target="_blank">
                        <img src="@/assets/img/wwh.svg" alt="" v-if="this.$route.name === 'Home'">
                        <img src="@/assets/img/wh.svg" alt="" v-else>
                    </a>
                    <a href="https://vk.com/sever.flora" target="_blank">
                        <img src="@/assets/img/wvk.svg" alt="" v-if="this.$route.name === 'Home'">
                        <img src="@/assets/img/vk.svg" alt="" v-else>
                    </a>
                </div>        
                <!-- <router-link v-if="!user" tag="div" to="/enter" class="hidden-lg hidden-md "
                    style="color:#fff;margin-right:0px !important;width: fit-content;">
                        <span class="mdi mdi-login"></span>
                        Вход
                    </router-link> -->
            </div>
        </div>
    </nav>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            priceList: 'content/getPriceList',
            user: 'auth/getAuthenticated',
            region: "catalog/getRegion",
        }),
        getReg(){
            if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                return 'sl'
            }else{
                return 'rg'
            }
        },
    },
    methods:{
        downloadPrice(){
            window.open(`${this.priceList[this.getReg]}`, '_blank')
        },
        goLibrary(){
            window.open(`https://library.sflora.ru/`, '_blank')
        }
    }    
}
</script>

<style scoped>
.main-nav li a{
    color: #fff;
    text-transform: uppercase;
}
.main-nav li a:hover{
    color: #B4DD45;
}
</style>