<template>
    <div class="mobile-nav">
        <div class="cross" @click="hideMenu($emit)">×</div>

        <ul @click="hideMenu($emit)">
            <router-link tag="li" to="/" class="wh-link">Главная</router-link>
            <router-link tag="li" to="/product-category" class="wh-link">Каталог и заказ растений</router-link>
            <li class="wh-link" @click="downloadPrice()">
                Скачать прайс
            </li>
            <li class="wh-link"  @click="goLibrary">Наш справочник</li>
            <router-link tag="li" to="/o-nas" class="wh-link">О нас</router-link>
            <router-link tag="li" to="/v-pitomnike" class="wh-link">В Питомнике</router-link>
            <router-link tag="li" to="/contacts" class="wh-link">Контакты</router-link>
            <router-link tag="li" to="/gde-kupit" class="wh-link">Где купить</router-link>
            
            <router-link tag="li" to="/cart" class="wh-link"><br> <span class="mdi mdi-cart"></span> Корзина - {{cart.length}}</router-link>
            
        </ul>
        <p class="black-txt"><a href="tel:+79213321380">+7 (921) 332-13-80</a></p>
        <p class="black-txt"><a href="tel:+79218634421">+7 (921) 863-44-21</a></p>
        <p class="black-txt"><a href="tel:+79218629421">+7 (921) 862-94-21 (м. Нарвская)</a></p>
        <br>
                        <p class="black-txt"><a href="mail:pitomnik@sflora.ru">pitomnik@sflora.ru</a></p>
                        <p class="black-white">Ленинградская область, <br>
                        Гатчинский район, д. Парицы, <br>
                        ул.Большая, д.69а
                        <br><br>
                        </p>

                    <div @click="hideMenu($emit)">
                        <router-link v-if="!user" tag="div" to="/enter" class="lk-btn"
                    style="margin-right:0px;width: fit-content;margin-bottom:20px;">
                        <span class="mdi mdi-login"></span>
                        Вход
                    </router-link>

                    <router-link v-else tag="div" to="/profile" class="lk-btn "
                    style="margin-right:0px;width: fit-content;margin-bottom:20px;">
                        <span class="mdi mdi-account"></span>
                        Личный кабинет
                    </router-link>
                    </div>


                    <div class="social-links">
                        <a href="https://wa.me/79213321380" target="blank_">
                            <img src="@/assets/img/wh.svg" alt="">
                        </a>
                        <a href="https://vk.com/sever.flora" target="blank_">
                            <img src="@/assets/img/vk.svg" alt="">
                        </a>
                    </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            user: "auth/getAuthenticated",
            priceList: 'content/getPriceList',
            cart: "cart/getCart",
            region: "catalog/getRegion",
        }),
        getReg(){
            if(this.region === 'Санкт-Петербург и ЛО (самовывоз на весну)'){
                return 'sl'
            }else{
                return 'rg'
            }
        }
    },
    methods: {
        hideMenu(){
            this.$emit("hideMenu")
        },
        downloadPrice(){
            window.open(`${this.priceList[this.getReg]}`, '_blank')
        },
        goLibrary(){
            window.open(`https://library.sflora.ru/`, '_blank')
        }
    }
}
</script>

<style scoped>
.black-txt{
    font-size: 18px;
}
.black-txt a{
    color: #000;
}
</style>