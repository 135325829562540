<template>
  <div class="maintecie text-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <img src="../../assets/img/dlogo.svg">
          <h2>Сайт в режиме разработки!</h2>
          <h4>
            Мы закончили прием заказов на самовывоз и рассылку. <br>
            До открытия питомника сайт будет находиться на обновлении. <br>

            Всю информацию о растениях можно узнать в разделе «Наш справочник»
          </h4>
          <a
            href="https://library.sflora.ru/"
            target="_blank"
          >
            <button
              class="green-btn"
              style="margin-bottom: 40px;"
            >Перейти в справочник</button>
          </a>
        </div>
        <div class="col-lg-4 col-lg-offset-4">
          <br>
          <label>Пароль для администратора</label>
          <input
            type="password"
            placeholder="Пароль"
            v-model="pass"
          >
          <button
            class="green-btn"
            @click="enterSite"
          >Войти</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pass: ''
    }
  },
  methods: {
    enterSite() {
      if (this.pass === 'sflora1337') {
        this.$emit('openSite')
      } else {
        alert('неверный пароль')
      }
    }
  }
}
</script>

<style scoped>
img {
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 20px;
}

.maintecie {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

input {
  background-color: #f2f2f2;
  border-radius: 0px;
  border: none;
  color: #000;
  font-size: 16px;
  padding: 8px 10px;
  margin-bottom: 0;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 25px;
  border: 1px solid #f2f2f2;
}
</style>